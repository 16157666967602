import * as React from 'react';
import { useState } from 'react'
import { AuthClient } from '@gid/gid-auth-sdk-public';

const Auth = () => {
    const [authState, setAuthState] = useState({});

    const [client] = React.useState(() => {
        return new AuthClient({
            clientId: '04c3fb1e-9739-4fde-b7c6-da461dced04b',
            scope: 'openid phone authorization_code_otp',
            authUrl: 'https://preprod.gid-auth.ru',
            gidUrl: 'https://preprod.gid-auth.ru',
            backendUrl: 'http://51.250.106.96',
            redirectUri: 'http://51.250.106.96',
            oauth2CodePath: '/api/v0.3/backend/oauth/token',
            // postLogoutUri: 'https://51.250.106.96/',
            buttons: [
                {
                    container: "#button1",
                    type: "standard",
                    color: "secondary",
                },
                {
                    container: "#button2",
                    type: "compact",
                }
            ],
            // callback: ({
            //     error_description,
            //     access_token = '',
            //     id_token = '',
            //     refresh_token = '',
            // }) => {
            //     const action = access_token && id_token
            //         ? { type: 'LOGIN_COMPLETE', access_token, id_token, refresh_token }
            //         : { type: 'ERROR', error: `������ ��� ��������� ������: ${error_description}` };
            //     dispatch(action);
            // },
            callback : ({
                error_description,
                access_token = '',
                id_token = '',
                refresh_token = '',
              }) => {
                if (access_token && id_token) {
                    console.log({ access_token, id_token, refresh_token })
                    setAuthState({ access_token, id_token, refresh_token });
                } else {
                    console.log({ access_token, id_token, refresh_token })
                    console.log({ error: `Ошибка при получении токена: ${error_description}` })
                    setAuthState({ error: `Ошибка при получении токена: ${error_description}` });
                }
            }
        })
    });

    React.useEffect(() => {
        client.init().then(() => {
            console.log('Auth service initiated')
        });
    }, [client]);

    return (
        <div className="section">
            <div className="button" id="button1" />
            <div className="button" id="button2" />
        </div>
    );
};

export default Auth;
